function ManipulateAssignments(tempJSON,id) {
    var arrAssignmentNew = [];
    tempJSON.forEach(function (arrItem) {

       // var sTimeStart = arrItem.starttime.replace('T', ' ') + ':00';
       // var sTimeEnd = arrItem.endtime.replace('T', ' ') + ':00';
        if (arrItem.starttime.length < 16){
            var sTimeStart = arrItem.starttime;}
        else{
            var sTimeStart = arrItem.starttime + ':00';
        
        }
        if (arrItem.endtime.length < 16){
            var sTimeEnd = arrItem.endtime;}
        else{
            var sTimeEnd = arrItem.endtime + ':00';
        
        }

        var lSingleContent = {id: arrItem.id.toString(),text: '',start: sTimeStart,end: sTimeEnd,resource: arrItem.stamm_id.id.toString()}; 
        lSingleContent.person = arrItem.stamm_id.id.toString();
        lSingleContent.location = id.toString();//$('#locations').val().toString();
        lSingleContent.join = arrItem.id.toString();
        arrAssignmentNew.push(lSingleContent);

        var lSingleContent1 = {id: 'L'+arrItem.id.toString(),text: '',start: sTimeStart,end: sTimeEnd,resource: 'L'+ id.toString()};//$('#locations').val().toString()}; 
        lSingleContent1.person = arrItem.stamm_id.id.toString();
        lSingleContent1.location = id.toString();//$('#locations').val().toString();
        lSingleContent1.type = 'location';
        lSingleContent1.join = arrItem.id.toString();
        arrAssignmentNew.push(lSingleContent1);
    });
    return arrAssignmentNew;
}
export default ManipulateAssignments;