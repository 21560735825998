
import './App.css';

import LocationContextProvider from './components/contexts/LocactionContext';
import RowContextProvider from './components/contexts/RowContext';
import Scheduler from './components/Scheduler';

function App() {
  return (
    <div className="App">
      <h1 className="shift">
        
        <LocationContextProvider>
          <RowContextProvider>
            
              <Scheduler/>
            
          </RowContextProvider>  
        </LocationContextProvider>
        
      </h1>
    </div>
  );
}

export default App;
