import { DayPilot, DayPilotScheduler } from "daypilot-pro-react";
import { useState, useContext, useRef } from "react";
import Buttons from "./Buttons";
import axios from "axios";
import Locations from "./Locations";
import { LocationContext } from "./contexts/LocactionContext";

import ManipulatePeople from "./ManipulatePeople";
import ManipulateAssignments from "./ManipulateAssignments";

const Scheduler = () => {
  const { firma } = useContext(LocationContext);

  const configref = useRef();
  const [config, setConfig] = useState({
    startDate: DayPilot.Date.today().firstDayOfMonth(),
    days: DayPilot.Date.today().daysInMonth(),
    scale: "CellDuration",
    locale: "de-de",
    cellDuration: 30,
    timeHeaders: [
      { groupBy: "Day", format: "dddd, d MMMM yyyy" },
      { groupBy: "Hour" },
      { groupBy: "Cell", format: "mm" },
    ],
    rowHeaderColumns: [{ name: "Name", display: "name" }, { name: "Total" }],
    resources: [],
    //events:[],
    timeRangeSelectedHandling: "Enabled",
    businessBeginsHour: 10,
    businessEndsHour: 4,
    showNonBusiness: false,
    businessWeekends: true,
    floatingEvents: true,

    eventHeight: 30,
    headerHeight: 30,
    treeEnabled: true,
    allowEventOverlap: true,
    multiMoveVerticalMode: "Master",
    eventResizeHandling: "Update",
    eventMoveHandling: "Update",
    dynamicEventRendering: "Disabled",

    onBeforeEventRender: (args) => {
      const isLocation = args.data.type === "location";
      //const inactive = args.data.type === "inactive";
      if (isLocation) {
        const person = configref.current.rows.find(args.data.person);
        args.data.backColor = "#bfd9a9";
        args.data.borderColor = "#7aa35d";
        args.data.barHidden = true;
        args.data.html = person ? person.name : "";
        args.data.moveVDisabled = true;
        args.data.areas = [
          {
            right: 5,
            top: 8,
            height: 22,
            width: 22,
            cssClass: "scheduler_default_event_delete",
            style:
              "background-color: #fff; border: 1px solid #ccc; box-sizing: border-box; border-radius: 10px; padding: 0px; border: 1px solid #999",
            visibility: "Visible",
            onClick: async (args) => {
              //const dp = configref.current;

              const modal = await DayPilot.Modal.confirm("Schicht löschen?");

              if (modal.canceled) {
                return;
              }
              const locationAssignment = args.source;

              const locationAssignmentId = locationAssignment.data.id;
              const personAssignmentId = locationAssignment.data.join;

              await axios.delete(
                "http://scheffler-hardcore.de:2010/hardcore/dp/DP_T_Plan(" +
                  personAssignmentId +
                  ")"
              ); //{id: personAssignmentId});

              configref.current.events.remove(locationAssignmentId);
              configref.current.events.remove(personAssignmentId);
              configref.current.links.remove(locationAssignmentId);

              //console.log('innerhalb onclick' + config);
            },
          },
        ];
      } else {
        const location = firma.name;
        const person = configref.current.rows.find(args.data.person);
        if (location) {
          args.data.html = location;
          args.data.moveHDisabled = true;
          args.data.backColor = person ? person.data.color : "#bfd9a9";

          args.data.borderColor = "#7aa35d";
          args.data.barHidden = true;
          args.data.bubbleHtml = person ? person.name : "";
        }
      }
    },
    onEventMoved: async (args) => {
      const dp = args.control;
      const e = args.e;
      alert("Moved: " + e.text());

      const res = await axios.patch(
        "http://scheffler-hardcore.de:2010/hardcore/dp/DP_T_Plan(" +
          e.data.join +
          ")",
        { starttime: args.newStart, endtime: args.newEnd }
      );
    },

    onEventResized: async (args) => {
      var e = args.e;
      alert("Event resized: " + args.e.text());
      console.log(e.data);
      const res = await axios.patch(
        "http://scheffler-hardcore.de:2010/hardcore/dp/DP_T_Plan(" +
          e.data.join +
          ")",
        { starttime: args.newStart, endtime: args.newEnd }
      );
      console.log(args.data);
    },

    onTimeRangeSelected: async (args) => {
      const dp = args.control; //zugriff auf das object das die events enthält.

      var locationId = firma.id;
      var iddata = { id: parseInt(args.resource) };
      dp.clearSelection();
      const res = await axios.post(
        "http://scheffler-hardcore.de:2010/hardcore/dp/DP_T_Plan",
        {
          starttime: args.start,
          endtime: args.end,
          location_id: parseInt(locationId),
          stamm_id: iddata,
        }
      );
      console.log(res);
      if (res.status === 201) {
        var data = res.data;
        var id = data.id;

        dp.events.add(
          new DayPilot.Event({
            start: args.start,
            end: args.end,
            id: id,
            resource: args.resource,
            location: locationId,
            person: args.resource,
            join: id,
          })
        );

        dp.events.add(
          new DayPilot.Event({
            start: args.start,
            end: args.end,
            id: "L" + id,
            resource: "L" + locationId,
            location: locationId,
            person: args.resource,
            type: "location",
            join: id,
          })
        );
        dp.links.list.push({
          id: "L" + id,
          from: "L" + id,
          to: id,
          type: "FinishToFinish",
          layer: "Above",
          color: "#e69138",
        });
        dp.update({
          events: dp.events,
          links: dp.links,
        });
      }
    },

    onBeforeRowHeaderRender: (args) => {
      const duration = args.row.events.totalDuration();
      const columnTotal = args.row.columns[1];
      if (duration.totalHours() > 0 && columnTotal) {
        columnTotal.text = duration.totalHours() + "h";
      }
      if (args.row.data.type === "location") {
        args.row.backColor = "#ffe599";
        args.row.fontColor = "#000";
        if (columnTotal) {
          columnTotal.fontColor = "#000";
        }
      } else {
        args.row.backColor = args.row.data.color ? args.row.data.color : "";
      }
    },
  });

  //useEffect(()=>{
  const LoadData = async () => {
    const locationId = firma.id;
    const firmaName = firma.name;

    const start = config.startDate;
    const end = config.startDate.addDays(config.days - 1);
    var Url = `http://scheffler-hardcore.de:2010/hardcore/dp/DP_T_Plan?$expand=stamm_id&$filter=location_id EQ ${locationId}`;
    Url += `and starttime GE  ${start.toString()} and endtime LE ${end.toString()}`;

    const promisePeople = axios.get(
      "http://scheffler-hardcore.de:2010/hardcore/dp/DP_T_Mitarbeiter?$orderby=Name"
    );

    const promiseAssignments = axios.get(Url);
    const [{ data: people }, { data: assignments }] = await Promise.all([
      promisePeople,
      promiseAssignments,
    ]);

    people.value = ManipulatePeople(people.value, firmaName);

    const rows = [
      { id: "L" + locationId, name: firmaName, type: "location" },
      ...people.value,
    ];

    const links = [];
    const assignment = ManipulateAssignments(assignments.value, locationId);

    assignment
      .filter((e) => e.type === "location")
      .forEach((e) => {
        links.push({
          id: e.id,
          from: e.id,
          to: e.join,
          type: "FinishToFinish",
          layer: "Above",
          color: "#e69138",
        });
      });

    scheduler.update({
      resources: rows,
      events: assignment,
      links,
    });

    configref.current = scheduler;

    console.log(scheduler);
  };

  let scheduler;
  return (
    <div className="scheduler">
      <Buttons config={config} setConfig={setConfig} />

      <Locations
        LoadData={LoadData}
        // ref={(component) => {
        //   scheduler = component && component.control;
        // }}
      />
      {/* <Locations LoadData={LoadData} /> */}

      <DayPilotScheduler
        {...config}
        ref={(component) => {
          scheduler = component && component.control;
        }}
      />
    </div>
  );
};

export default Scheduler;
