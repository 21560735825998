import React,{createContext,useRef} from 'react';
//const personal={id:0, name:'', color:''};
export const RowContext = createContext([]);

const RowContextProvider=(props)=>{
    //const [personal, setPersonal] = useState([{id:0, name:'', color:''}]);
    const personal=useRef([{id:0, name:'', color:''}]);
    
    return (
        <RowContext.Provider value={{personal}} >
            {props.children}
        </RowContext.Provider>
    )

}

export default RowContextProvider;