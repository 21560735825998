
function ManipulatePeople(tempJSON,location) {
    var arrPeopleNew = [];
    tempJSON.forEach(function (arrItem) {
        if (arrItem.Betrieb.indexOf(location)===0) {
            arrPeopleNew.push({id: arrItem.id.toString(), name: arrItem.Name,color:arrItem.farbe});
        };
    });
    return arrPeopleNew;
}
export default ManipulatePeople;