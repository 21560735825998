


const Buttons = ({config,setConfig}) => {
    
    const handleClick = (e) => {
        if(e.target.id==='nextMonth'){
         setConfig(prevState=>{
          return{ ...prevState, startDate:config.startDate.addMonths(1),
            days:config.startDate.addMonths(1).daysInMonth() }});
           
         }else{
          setConfig(prevState=>{
            return{ ...prevState, startDate:config.startDate.addMonths(-1),
              days:config.startDate.addMonths(-1).daysInMonth() }}); 
         }
      };
       
        
   
     
    
    
    return ( 
        <div className="buttons">
            &nbsp;&nbsp;&nbsp;
            <button id="nextMonth" onClick={(e)=>handleClick(e)}>Monat +</button>
            &nbsp;
            <button id="prevMonth" onClick={(e)=>handleClick(e)}>Monat -</button>
        </div>
     );
}
 
export default Buttons;