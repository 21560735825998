import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { LocationContext } from "./contexts/LocactionContext";

const Locations = ({ LoadData }) => {
  const { updateFirma } = useContext(LocationContext);
  const [data, setData] = useState();
  const { firma } = useContext(LocationContext);
  const [betrieb, setBetrieb] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        "http://scheffler-hardcore.de:2010/hardcore/dp/DP_L_Location"
      );
      if (result.statusText === "OK") {
        console.log(result.data.value);
        setData(result.data.value);
        firma.id = result.data.value[0].id; //ändern - sonst ist die location id immer 1
        firma.name = result.data.value[0].name;
        updateFirma(firma);
      }
    };

    fetchData();
  }, [firma, updateFirma]);

  useEffect(() => {
    LoadData();
    console.log("inside locations");
  });

  const handleChange = (e) => {
    firma.id = parseInt(e.target.value); //options.selectedIndex;
    firma.name = e.target.options[e.target.selectedIndex].innerText;
    updateFirma(firma);
    setBetrieb(firma.name);

    //console.log(firma);
  };
  //console.log(props&&props.name);

  return (
    <div className="space">
      Location: &nbsp;
      <select id="locations" onChange={(ev) => handleChange(ev)}>
        {data &&
          data.map((location) => (
            <option key={location.id} value={location.id}>
              {location.name}
            </option>
          ))}
      </select>
    </div>
  );
};

//get selectedValue() {
//  return this.select.value;
//}

export default Locations;
